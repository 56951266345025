import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import listBookingRsa from './components/listBookingRsa.vue'
import parenti from './components/parenti.vue'
import editParente from './components/editParente.vue'
import tipologiapagamento from './components/tipologiapagamento.vue'
import editTipologiaPagamento from './components/editTipologiaPagamento.vue'
import pagamenti from './components/pagamenti.vue'
import editPagamento from './components/editPagamento.vue'
import mailGruppo from './components/mailGruppo.vue'
import editMailGruppo from './components/editMailGruppo.vue'
import copyPagamento from './components/copyPagamento.vue'
import confInvioMail from './components/confInvioMail.vue'
import reservate from './components/reservate.vue'
import filterPagamenti from './components/filterPagamenti.vue'
import chooseSubjectPayment from './components/chooseSubjectPayment.vue'
import gruppoNotifiche from './components/gruppoNotifiche.vue'
import editGruppoNotifiche from './components/editGruppoNotifiche.vue'
import insurance from './components/insurance.vue'
import chooseSubjectInsurance from './components/chooseSubjectInsurance.vue'
import filterInsurance from './components/filterInsurance.vue'
import editInsurance from './components/editInsurance.vue'
import copyInsurance from './components/copyInsurance.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import subscribeNotification from './components/subscribeNotification.vue'

import subscribeNotification2 from './components/subscribeNotification2.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/listBookingRsa',
            name: 'listBookingRsa',
            component: listBookingRsa
        }

        ,
        {
            path: '/parenti',
            name: 'parenti',
            component: parenti
        }

        ,
        {
            path: '/editParente/:id',
            name: 'editParente',
            component: editParente,
            props: true
        }



        ,
        {
            path: '/tipologiapagamento',
            name: 'tipologiapagamento',
            component: tipologiapagamento
        }


        ,
        {
            path: '/editTipologiaPagamento/:id',
            name: 'editTipologiaPagamento',
            component: editTipologiaPagamento,
            props: true
        }

        ,
        {
            path: '/pagamenti',
            name: 'pagamenti',
            component: pagamenti,
            props: true
        }

        ,
        {
            path: '/editPagamento/:id',
            name: 'editPagamento',
            component: editPagamento,
            props: true
        }

        ,
        {
            path: '/mailGruppo',
            name: 'mailGruppo',
            component: mailGruppo,
            props: true
        }

        ,
        {
            path: '/editMailGruppo/:id',
            name: 'editMailGruppo',
            component: editMailGruppo,
            props: true
        }

        ,
        {
            path: '/copyPagamento/:id',
            name: 'copyPagamento',
            component: copyPagamento,
            props: true
        }

        ,
        {
            path: '/confInvioMail',
            name: 'confInvioMail',
            component: confInvioMail,
            props: true
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/filterPagamenti',
            name: 'filterPagamenti',
            component: filterPagamenti,
            props: true
        }

        ,
        {
            path: '/chooseSubjectPayment',
            name: 'chooseSubjectPayment',
            component: chooseSubjectPayment,
            props: true
        }

        ,
        {
            path: '/gruppoNotifiche',
            name: 'gruppoNotifiche',
            component: gruppoNotifiche,
            props: true
        }

        ,
        {
            path: '/editGruppoNotifiche/:id',
            name: 'editGruppoNotifiche',
            component: editGruppoNotifiche,
            props: true
        }

        ,
        {
            path: '/insurance',
            name: 'insurance',
            component: insurance,
            props: true
        }


        ,
        {
            path: '/chooseSubjectInsurance',
            name: 'chooseSubjectInsurance',
            component: chooseSubjectInsurance,
            props: true
        }

        ,
        {
            path: '/filterInsurance',
            name: 'filterInsurance',
            component: filterInsurance,
            props: true
        }

        ,
        {
            path: '/editInsurance/:id',
            name: 'editInsurance',
            component: editInsurance,
            props: true
        }

        ,
        {
            path: '/copyInsurance/:id',
            name: 'copyInsurance',
            component: copyInsurance,
            props: true
        },

        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial
        },

        {
            path: '/subscribeNotification',
            name: 'subscribeNotification',
            component: subscribeNotification
        },

        {
            path: '/subscribeNotification2',
            name: 'subscribeNotification2',
            component: subscribeNotification2
        }
        

    ]
})


    router.afterEach((to, from) => {
        console.log ("from" , from  );
        console.log("going to " + to.fullPath)


    })


export default router
