<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">

        <v-col v-if="viewPagamenti == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/chooseSubjectPayment">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/pagamenti-alertduedate.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Pagamenti</div>
                </template>
                <span>Pagamenti</span>
            </v-tooltip>

        </v-col>



    </v-container>
</template>

<script>


import apiusers from "../utils/users/apiusers";
import apinotification from "../utils/notification/apinotification";

export default {
    name: "HelloWorld",

    mounted() {

        this.$root.$children[0].checkLogout();

        this.checkChangePwd();


        var info_global = JSON.parse(localStorage.getItem('al_info_global'));

        console.log("RESULT_DATA COOKIE: ", info_global);

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }


        if (this.isSU == 0) {


            this.viewGroupMail = this.getEnableConf("grp_mail_alertduedate");
            this.viewConfInvioMail = this.getEnableConf("conf_invio_mail_alertduedate");
            this.viewSlider = this.getEnableConf("slider_alertduedate");
            this.viewTipologie = this.getEnableConf("tipologie_alertduedate");
            this.viewPagamenti = this.getEnableConf("pagamenti_alertduedate");
            this.viewProfilo = this.getEnableConf("profilo_alertduedate");


        }
        else {


            this.viewGroupMail = 1;
            this.viewConfInvioMail = 1;
            this.viewSlider = 1;
            this.viewTipologie = 1;
            this.viewPagamenti = 1;
            this.viewProfilo = 1;

        }

        this.getLastLogin();

        this.checkRegNotification();

    },

    methods: {


        getUrlScript: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = "/scripts/service-worker.js";
            }

            if (location.hostname != "localhost") {

                result = "/alertduedate/scripts/service-worker.js";

            }

            return result;

        },

        arrayBufferToBase64: function (buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },

        urlB64ToUint8Array: function (base64String) {

            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;



        },

        saveRegistration: async function (product, endpoint, publickey, token) {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apinotification.saveRegistration(
                v_token,
                product,
                endpoint,
                publickey,
                token
            ).then((res) => {

                console.log("res from saveRegistration", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Notifiche abilitate con successo",
                        showConfirmButton: false,
                        timer: 3000
                    });

                    setTimeout(() => {

                        var pulsantiNotif2 = [];

                        pulsantiNotif2.push({
                            text: "",
                            icon: "mdi-account",
                            link: "",
                            id: "btnEnableNotification",
                            disabled: false,
                        });


                        that.$root.$children[0].buttonsEnableNotification = pulsantiNotif2;

                        that.$root.$children[0].buttonsDisableNotification = [];



                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        subscribeUserToPush: function (registration) {

            var that = this;


            const applicationServerKey = that.urlB64ToUint8Array('BOSsMJaAgY7z9C8MsxRqf-wa7JTx_rE7E0o6i9snxoCjGASxBh_jr-0NU34tkJS1OoMzdDZzDsXIClNLekQ9oJ0');
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            })
                .then(function (subscription) {
                    console.log('User is subscribed:', subscription);



                    that.saveRegistration("alertduedate",
                        subscription.endpoint,
                        that.arrayBufferToBase64(subscription.getKey('p256dh')),
                        that.arrayBufferToBase64(subscription.getKey('auth')));



                })
                .catch(function (error) {
                    console.error('Failed to subscribe the user:', error);
                });


        },

        doCheckRegNotification: async function () {

            var that = this;

            if ('serviceWorker' in navigator) {


                navigator.serviceWorker.register(that.getUrlScript())
                    .then(function (registration) {

                        Notification.requestPermission().then(function (permission) {

                            if (permission === 'granted') {

                                setTimeout(() => {


                                    registration.pushManager.getSubscription().then(function (subscription) {

                                        console.log("subscription: ", subscription);

                                        if (subscription) {

                                            console.log("ok");

                                            var pulsantiNotif3 = [];

                                            pulsantiNotif3.push({
                                                text: "",
                                                icon: "mdi-account",
                                                link: "",
                                                id: "btnEnableNotification",
                                                disabled: false,
                                            });


                                            that.$root.$children[0].buttonsEnableNotification = pulsantiNotif3

                                        }
                                        else {



                                            that.$swal
                                                .fire({
                                                    title: "Notifiche",
                                                    html: "Vuoi abilitare la ricezione delle notifiche ?",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png' />",
                                                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png' />",
                                                    customClass: {
                                                        cancelButton: 'order-1',
                                                        confirmButton: 'order-2',
                                                    }

                                                })
                                                .then(async function (result) {

                                                    if (result.value == true) {

                                                        console.log("ok");



                                                        that.subscribeUserToPush(registration);


                                                    }
                                                    else {

                                                        var pulsantiNotif = [];

                                                        pulsantiNotif.push({
                                                            text: "",
                                                            icon: "mdi-account",
                                                            link: "",
                                                            id: "btnDisableNotification",
                                                            disabled: false,
                                                        });


                                                        that.$root.$children[0].buttonsDisableNotification = pulsantiNotif


                                                    }

                                                });





                                        }





                                    });





                                }, 200);




                            }






                        });




                    });



            }


        },


        checkRegNotification: async function () {

            var that = this;

            var checkRegNotification = window.$cookies.get("checkRegNotification");

            if (checkRegNotification == 1) {

                window.$cookies.set("checkRegNotification", 0, "9y");


                that.doCheckRegNotification();


            }


        },







        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "alertduedate"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        checkChangePwd: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkChangePwd(
                atob(v_token)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkChangePwd", res);

                if (res.data.IsChanged == 0) {

                    that.$root.$children[0].logout();


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },

    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({

        isSU: 0,
        isAdmin: 0,

        viewGroupMail: 0,
        viewConfInvioMail: 0,
        viewSlider: 0,
        viewTipologie: 0,
        viewPagamenti: 0,
        viewProfilo: 0,


        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
